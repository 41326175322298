@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-ghost{
  border-color: #73bd19;
  color: #73bd19;
  font-weight: bold;
  padding: 7px 20px 10px 20px;
  height: 38px;
}

.ant-btn-primary {
  background-color: #73bd19;
  color: white;
  font-weight: bold;
  padding: 7px 20px 10px 20px;
  height: 38px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff; 
  background-color: #73bd19 !important; 
  border-color: #73bd19 !important; 
}
.ant-radio-button-wrapper:hover:not(.ant-radio-button-wrapper-disabled) {
  color: #73bd19 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #000 !important;
}
.ant-btn-primary:hover, .ant-btn-primary:focus {
  background-color: #4c8d13 !important;
  color: white;
}

.ant-btn-dangerous:hover {
  background-color: rgb(199, 59, 59) !important;
  color: white;
}


.ant-btn-ghost:hover, .ant-btn-ghost:focus {
  color: #4c8d13 !important;
  border-color: #4c8d13 !important;
}
